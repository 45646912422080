import React from 'react'
import PropType from 'prop-types'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

import styles from './index.module.scss'

const Row = (props) => {
  const { question, disabled, value, validation, idx } = props
  const { label, variable, canFormulaCollapse, description, placeholder } = question
  const isFormula = Object.prototype.hasOwnProperty.call(question, 'formula')
  const componentDisabled = (isFormula && !value.isCollapsed) || disabled
  const onChange = (e) => {
    const newValue = {
      value: e.target.value
    }
    props.onChange({
      target: {
        name: variable,
        value: { ...value, ...newValue }
      },
      question
    })
  }
  const collapseQuestion = e => {
    e.preventDefault()
    if (props.collapseQuestion) {
      props.collapseQuestion({ question, value })
    }
  }
  return (
    <tr className={styles.row}>
      <td className={styles['cell__action']}>
        {
          canFormulaCollapse && (value.isCollapsed ? <i className="fa fa fa-angle-right" onClick={collapseQuestion} /> : <i className={classNames({
            fas: true,
            fa: true,
            'fa-angle-down': idx === 0,
            'fa-angle-up': idx !== 0
          })}
          onClick={collapseQuestion} />)
        }
      </td>
      <td className={styles.cell}>
        <span>{label}</span>
        {
          description &&
          <>
            <span>
              <a className='help' data-tip={description} data-for={`question-description-${variable}`}>
                <i className="fa fa-question-circle" />
              </a>
            </span>
            <ReactTooltip id={`question-description-${variable}`} class="tool-tip-window" aria-haspopup="true" place='right' effect='solid' />
          </>
        }
      </td>
      <td className={styles['cell__input']}>
        <input
          className={classNames({
            [styles.disabled]: componentDisabled
          })}
          value={value.value}
          onChange={onChange}
          placeholder={placeholder}
          type="text"
          disabled={componentDisabled}
        />
        <div hidden={!validation} className={classNames({
          error: true,
          [styles['error__message']]: true
        })}>
          {validation}
        </div>
      </td>
    </tr>
  )
}

Row.defaultProps = {
  value: {
    value: ''
  }
}

Row.propTypes = {
  validation: PropType.string,
  collapseQuestion: PropType.func,
  disabled: PropType.bool,
  question: PropType.object,
  onChange: PropType.func,
  value: PropType.object,
  idx: PropType.number
}

export default Row