import React from 'react'
import PropTypes from 'prop-types'
import { chunk } from 'lodash'
import classNames from 'classnames'
import style from './index.module.scss'

const DEFAULT_PLACEHOLDER_FOR_TEXT_INPUT = 'Please specify other'
const RadioInput = props => {
  const {
    disabled,
    options
  } = props
  const value = props.value.value
  const textValue = props.value.textValue || ''

  const onOptionChange = optionValue => {
    const newVal = {
      ...props.value,
      value: optionValue
    }
    delete newVal.textValue
    props.onChange(newVal)
  }

  const onTextChange = e => {
    const newVal = {
      ...props.value,
      textValue: e.target.value
    }
    props.onChange(newVal)
  }

  if (options && options.length <= 5) {
    return (
      <div
        className={style['radio__container']}
      >
        {
          options.map((option, idx) => {
            const { optionLabel, optionValue, optionEnableTextInput, optionTextInputPlaceholder } = option
            return (
              <div className={style['option__container']} key={idx}>
                <input
                  checked={optionValue === value}
                  className={style['option__select']}
                  disabled={disabled}
                  onChange={() => onOptionChange(optionValue)}
                  type='radio'
                />
                <label
                  className={style['option__label']}
                >
                  {optionLabel}
                </label>
                {
                  optionEnableTextInput && optionValue === value && (
                    <input
                      className={classNames({
                        'text-input': true,
                        [style['text__input--inline']]: true
                      })}
                      disabled={disabled}
                      onChange={onTextChange}
                      placeholder={optionTextInputPlaceholder || DEFAULT_PLACEHOLDER_FOR_TEXT_INPUT}
                      type='text'
                      value={textValue}
                    />
                  )
                }
              </div>
            )
          })
        }
      </div>
    )
  }
  return (
    <div
      className={style['radio__container']}
    >
      {chunk(options, 2).map((setOfOption, idx1) => (
        <div className={style['radio__row']} key={idx1}>
          {
            setOfOption.map((option, idx2) => {
              const { optionLabel, optionValue, optionEnableTextInput, optionTextInputPlaceholder } = option
              return (
                <div
                  className={style['option__container--col-12']}
                  key={idx2}
                >
                  <input
                    checked={optionValue === value}
                    className={style['option__select']}
                    disabled={disabled}
                    onChange={() => onOptionChange(optionValue)}
                    type='radio'
                  />
                  <label
                    className={style['option__label']}
                  >
                    {optionLabel}
                  </label>
                  {
                    optionEnableTextInput && optionValue === value && (
                      <input
                        className={classNames({
                          'text-input': true,
                          [style['text__input--inline']]: true
                        })}
                        disabled={disabled}
                        onChange={onTextChange}
                        placeholder={optionTextInputPlaceholder || DEFAULT_PLACEHOLDER_FOR_TEXT_INPUT}
                        type='text'
                        value={textValue}
                      />
                    )
                  }
                </div>
              )
            })
          }
        </div>
      ))}
    </div>
  )
}

RadioInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  variable: PropTypes.string
}

export default RadioInput