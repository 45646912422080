import React from 'react'
import RcTimePicker from 'rc-time-picker'
import moment from 'moment'
import PropTypes from 'prop-types'

import { QS_CONSTANTS } from '../../constants'


const genNewVal = (time, format, oldVal) => {
  const newVal = {
    value: ''
  }
  if (time) {
    newVal.value = time.format(format)
  }
  return { ...oldVal, ...newVal }
}

const TimePicker = props => {
  const { disabled, placeholder, format } = props
  const formatLowerCase = format.toLowerCase()
  const showHour = formatLowerCase.includes('h')
  const showMinute = formatLowerCase.includes('m')
  const showSecond = formatLowerCase.includes('s')
  const value = moment(props.value.value, format)
  const isValueValid = value.isValid()
  const onChange = time => {
    const newVal = genNewVal(time, format, props.value)
    props.onChange(newVal)
  }
  const onOpen = () => {
    if (!value.isValid()) {
      const newVal = genNewVal(moment(), format, props.value)
      props.onChange(newVal)
    }
  }

  return (
    <RcTimePicker
      disabled={disabled}
      onChange={onChange}
      onOpen={onOpen}
      showHour={showHour}
      showMinute={showMinute}
      showSecond={showSecond}
      placeholder={placeholder}
      value={isValueValid ? value : null}
    />
  )
}

TimePicker.defaultProps = {
  format: QS_CONSTANTS.DEFAULT_TIME_FORMAT
}

TimePicker.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired
}

export default TimePicker
