// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".App_engine__wrapper__iuc1v {\n  width: calc(100vw - 100px);\n  height: calc(100vh - 100px);\n  margin: 50px;\n  overflow: auto;\n  position: relative; }\n", ""]);
// Exports
exports.locals = {
	"engine__wrapper": "App_engine__wrapper__iuc1v"
};
module.exports = exports;
