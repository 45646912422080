import React from 'react'
import PropType from 'prop-types'

import { QS_CONSTANTS } from '../../constants'
import Row from './Row'
import styles from './index.module.scss'

const Table = (props) => {
  const { questionSet, questionSetAnswers, disabled, path, validations } = props
  const onChange = ({ target, question }) => {
    props.onChange({ target }, question.variable, questionSet.name, QS_CONSTANTS.INPUT_TYPE.TEXT, questionSet, question, path)
  }
  return (
    <table className={styles.table}>
      <tbody>
        {questionSet.questions.map((question, idx) => {
          const { variable, hidden } = question
          const value = questionSetAnswers[variable]
          const ifQuestionDisplay = props.checkIfQuestionDisplay(path, question) && !value?.isHidden && !hidden
          if (ifQuestionDisplay) {
            const validation = validations ? validations[variable] : ''
            const conditionalProps = {}
            if (question.canFormulaCollapse) {
              conditionalProps.collapseQuestion = props.collapseQuestion
            }
            return <Row
              disabled={disabled}
              question={question}
              onChange={onChange}
              value={value}
              validation={validation}
              key={idx}
              idx={idx}
              {...conditionalProps}
            />
          }
          return null
        })}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  collapseQuestion: PropType.func,
  disabled: PropType.bool,
  onChange: PropType.func.isRequired,
  path: PropType.array.isRequired,
  questionSet: PropType.object.isRequired,
  questionSetAnswers: PropType.object.isRequired,
  validations: PropType.object,
  checkIfQuestionDisplay: PropType.func.isRequired
}

export default Table