// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../example/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DateTimePicker_dateTime__container__1hatR input {\n  width: 160px;\n  padding: 4px 7px; }\n  .DateTimePicker_dateTime__container__1hatR input::-moz-placeholder {\n    font-size: 12px; }\n  .DateTimePicker_dateTime__container__1hatR input:-ms-input-placeholder {\n    font-size: 12px; }\n  .DateTimePicker_dateTime__container__1hatR input::placeholder {\n    font-size: 12px; }\n", ""]);
// Exports
exports.locals = {
	"dateTime__container": "DateTimePicker_dateTime__container__1hatR"
};
module.exports = exports;
