// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../example/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Table_table__2YHTh {\n  padding: 5px; }\n", ""]);
// Exports
exports.locals = {
	"table": "Table_table__2YHTh"
};
module.exports = exports;
