const QS_CONSTANTS = {
  AUTHORIZED: 'authorized',
  BUSINESS_RULE: 'businessRule',
  CHANGE_OPTIONS: 'change options',
  CREATE_VALUE: 'Other (Specify)',
  DISPLAY: 'display',
  DEFAULT_TIME_FORMAT: 'HH:mm:ss',
  DEFAULT_DATE_FORMAT: 'DD/MM/YYYY',
  FORMAT: 'format',
  INVALID_CSS: 'valid',
  INPUT_TYPE: {
    CHECKBOX: 'checkbox',
    CHECKBOX_IMAGE: 'checkboxWithImage',
    DATE: 'date',
    INLINE_RADIO: 'inlineRadio',
    INPUT_TYPE: 'inputType',
    RADIO: 'radio',
    SELECT: 'select',
    SLIDER: 'slider',
    TEXT: 'text',
    TEXT_AREA: 'textArea',
    TIME: 'time',
    DATE_TIME: 'datetime'
  },
  CONTENT_TYPE: {
    PLAIN_TEXT: 'PLAIN_TEXT'
  },
  MULTIPLICATION: 'multiplication',
  SHOW_IF: 'showIf',
  OTHER_ANSWER: 'Other (specify)',
  OPTIONS: 'options',
  QUESTION_TYPE: {
    QUESTION: 'QUESTION',
    QUESTION_SET: 'QUESTION_SET',
    TEXT_NOTE: 'TEXT_NOTE'
  },
  QUESTION_FORMAT: {
    ROW: 'row'
  },
  REPEAT_SET: 'repeat set',
  RULE: 'rule',
  TEXT_VALIDATION: 'text validation',
  USER_TYPES: {
    ADMIN: 'ADMIN',
    ANYONE: 'ANYONE',
    CODER_A: 'CODER_A',
    CODER_B: 'CODER_B',
    USER: 'USER'
  }
}


module.exports = {
  QS_CONSTANTS
}
