import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const PlainText = (props) => {
  const { content } = props

  return <pre className={styles.pre}>{content}</pre>
}

PlainText.propTypes = {
  content: PropTypes.string,
}

export default PlainText