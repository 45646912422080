import React, { useCallback, useState } from 'react'
import FormEngine from '../../../../lib'
import { equals } from 'ramda'

import options from '../../data/southAu/options'
import questionSet from '../../data/southAu/questionSet'
import style from './index.module.scss'
import { getRecord } from '../../api'

const App = () => {
  const [answers, setAnswers] = useState({})
  const [progress, setProgress] = useState({})
  const [disabled, setDisabled] = useState(false)
  const [page, setPage] = useState(1)

  const onAnswersChange = newAnswers => {
    if (!equals(newAnswers, answers)) {
      setAnswers(newAnswers)
    }
  }

  const fetchRecord = async () => {
    const record = await getRecord()
    setAnswers(record)
  }

  const switchFormStatus = () => setDisabled(!disabled)

  const memorizedOnAnswersChange = useCallback(onAnswersChange, [setAnswers])
  return (
    <div
      className={style['engine__wrapper']}
    >
      <div
        className={style['engine__control']}
      >
        <button onClick={fetchRecord}> Fetch data</button>
        <button onClick={switchFormStatus}> { disabled ? 'Enable' : 'Disable' } form </button>
        <div className={style['pagination']}>
          {
            questionSet.questions.map((question, idx) => <button key={idx} onClick={() => setPage(idx + 1)}>{idx + 1}</button>)
          }
        </div>
      </div>
      <FormEngine
        answers={answers}
        currentSectionIndex={page - 1}
        fieldDisabled={disabled}
        onAnswersChange={memorizedOnAnswersChange}
        onProgressChange={setProgress}
        progress={progress}
        questionSet={questionSet}
        vocabularies={options}
        // ignoreShowIf={true}
      />
    </div>
  )
}

export default App