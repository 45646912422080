// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../example/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RadioInput_radio__container__2oF6H {\n  display: flex;\n  flex-direction: column;\n  position: relative; }\n\n.RadioInput_option__container__2LUzl {\n  align-items: center;\n  align-items: center;\n  display: flex;\n  padding: 2px;\n  position: relative; }\n\n.RadioInput_option__container--col-12__szfF4 {\n  align-items: center;\n  display: flex;\n  padding: 2px;\n  position: relative;\n  width: 50%; }\n\n.RadioInput_option__select__YCVri {\n  height: 21px;\n  width: 21px;\n  flex: 0 0 21px;\n  margin: 3px; }\n\n.RadioInput_option__label__gjqRi {\n  font-size: 14px;\n  margin-left: 5px;\n  word-break: break-word; }\n\n.RadioInput_radio__row__6UMtl {\n  display: inline-flex;\n  width: 100%; }\n\n.RadioInput_text__input--inline__2KqCj {\n  width: 50% !important;\n  height: 21px !important;\n  margin-left: 5px !important; }\n", ""]);
// Exports
exports.locals = {
	"radio__container": "RadioInput_radio__container__2oF6H",
	"option__container": "RadioInput_option__container__2LUzl",
	"option__container--col-12": "RadioInput_option__container--col-12__szfF4",
	"option__select": "RadioInput_option__select__YCVri",
	"option__label": "RadioInput_option__label__gjqRi",
	"radio__row": "RadioInput_radio__row__6UMtl",
	"text__input--inline": "RadioInput_text__input--inline__2KqCj"
};
module.exports = exports;
