// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../example/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CheckboxInput_checkbox__container__2MOxM {\n  display: flex;\n  flex-direction: column;\n  position: relative; }\n\n.CheckboxInput_option__container__2eMRi {\n  align-items: center;\n  align-items: center;\n  display: flex;\n  padding: 2px;\n  position: relative; }\n\n.CheckboxInput_option__container--col-12__eOEya {\n  align-items: center;\n  display: flex;\n  padding: 2px;\n  position: relative;\n  width: 50%; }\n\n.CheckboxInput_option__select__2C5Ff {\n  height: 21px;\n  width: 21px;\n  flex: 0 0 21px;\n  margin: 3px; }\n\n.CheckboxInput_option__label__1dcgR {\n  font-size: 14px;\n  margin-left: 5px;\n  word-break: break-word; }\n\n.CheckboxInput_checkbox__row__2RIJS {\n  display: inline-flex;\n  width: 100%; }\n\n.CheckboxInput_text__input--inline__1v5C5 {\n  width: 50% !important;\n  height: 21px !important;\n  margin-left: 5px !important; }\n", ""]);
// Exports
exports.locals = {
	"checkbox__container": "CheckboxInput_checkbox__container__2MOxM",
	"option__container": "CheckboxInput_option__container__2eMRi",
	"option__container--col-12": "CheckboxInput_option__container--col-12__eOEya",
	"option__select": "CheckboxInput_option__select__2C5Ff",
	"option__label": "CheckboxInput_option__label__1dcgR",
	"checkbox__row": "CheckboxInput_checkbox__row__2RIJS",
	"text__input--inline": "CheckboxInput_text__input--inline__1v5C5"
};
module.exports = exports;
