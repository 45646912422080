import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Creatable from 'react-select/creatable'

const convertToSelectOptions = (options) => {
  const selectOptions = options.filter(option => {
    const { optionEnableTextInput } = option
    return !optionEnableTextInput
  }).map(option => ({
    label: option.optionLabel,
    value: option.optionValue
  }))
  const isCreatable = selectOptions.length !== options.length
  return [selectOptions, isCreatable]
}
const SelectInput = props => {
  const {
    disabled,
    variable
  } = props
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(null)
  const [isCreatable, setIsCreatable] = useState(false)

  const onOptionChange = option => {
    const newVal = {
      ...props.value,
      value: option?.value !== undefined ? option.value : ''
    }
    const evt = {
      target: {
        value: newVal,
        name: variable
      }
    }
    props.onChange(evt)
  }

  useEffect(() => {
    if (props.options) {
      const [options, isCreatable] = convertToSelectOptions(props.options)
      setOptions(options)
      setIsCreatable(isCreatable)
    }
  }, [props.options])

  useEffect(() => {
    if (props.value) {
      let option = options.find(option => option.value === props.value.value)
      if (isCreatable && !option && props.value && props.value.value !== '') {
        option = {
          label: props.value.value,
          value: props.value.value
        }
      }
      // null for unselect
      setValue(option || null)
    } 
  }, [props.value, options, isCreatable])


  if (isCreatable) {
    return (
      <Creatable
        isClearable
        value={value}
        options={options}
        isDisabled={disabled}
        onChange={onOptionChange}
      />
    )
  }
  return (
    <Select
      isClearable
      value={value}
      options={options}
      isDisabled={disabled}
      onChange={onOptionChange}
    />
  )
}

SelectInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  variable: PropTypes.string.isRequired
}

export default SelectInput