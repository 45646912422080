import DatePickerInput from 'react-day-picker/DayPickerInput'
import PropTypes from 'prop-types'
import React from 'react'
import { formatDate, parseDate } from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'

import { QS_CONSTANTS } from '../../constants'

const genNewVal = (date, format, oldVal) => {
  const newValue = {
    value: formatDate(date, format)
  }
  return { ...oldVal, ...newValue }
}

const DatePicker = props => {
  const { disabled, format, placeholder } = props
  const value = props.value.value ? parseDate(props.value.value, format) : ''
  const onChange = date => {
    const newVal = genNewVal(date, format, props.value)
    props.onChange(newVal)
  }

  const onDayPickerShow = () => {
    if (value === '') {
      const date = new Date()
      const newVal = genNewVal(date, format, props.value)
      props.onChange(newVal)
    }
  }

  return (
    <div>
      <DatePickerInput
        onDayChange={onChange}
        value={value}
        format={format}
        placeholder={placeholder}
        formatDate={formatDate}
        parseDate={parseDate}
        inputProps={{ disabled }}
        onDayPickerShow={onDayPickerShow}
      />
    </div>
  )
}

DatePicker.defaultProps = {
  format: QS_CONSTANTS.DEFAULT_DATE_FORMAT
}

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object.isRequired,
}

export default DatePicker