import * as _ from 'lodash'
import PropType from 'prop-types'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import Slider from 'rc-slider'
import Tooltip from 'rc-tooltip'
import Youtube from 'react-youtube'
import classNames from 'classnames'
import moment from 'moment'
import { QS_CONSTANTS } from './constants'
import { evaluate } from 'mathjs'


import components from './components'

import './styles/index.scss'
import 'rc-slider/assets/index.css'
import 'rc-time-picker/assets/index.css'

const Handle = Slider.Handle
const momentDurationFormatSetup = require('moment-duration-format')

momentDurationFormatSetup(moment)
/**
 * Component to render video.
 * @returns {*}
 * @constructor
 * @param props
 */
const RenderVideo = props => {

  let opts = {
    height: 500,
    width: 700
  }

  return (
    <div className="videoDisplay">
      <Youtube
        videoId={props.videoId}
        opts={opts}
      />
    </div>
  )
}

RenderVideo.propTypes = {
  videoId: PropType.string
}
/**
 * Study data entry form.
 */
class StudyQuestionsForm extends React.Component {
  constructor(props) {
    super(props)

    this.checkBusinessRule = this.checkBusinessRule.bind(this)
    this.getAnswerWithPath = this.getAnswerWithPath.bind(this)
    this.getDominantPath = this.getDominantPath.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.isTextAreaVisible = this.isTextAreaVisible.bind(this)
    this.progress = {}
    this.validations = {}
    this.reloadProgress = this.reloadProgress.bind(this)
    this.renderQuestionSet = this.renderQuestionSet.bind(this)
    this.renderTextInput = this.renderTextInput.bind(this)
    this.renderTextArea = this.renderTextArea.bind(this)
    this.setAnswer = this.setAnswer.bind(this)
    this.components = Object.assign({}, components, props.components)
    this.state = {
      touchedQuestionPath: []
    }
  }
  /**
   * Check if the question is going to be displyed.
   * @param path The path to answer that stores the value to current question.
   * @param question Question object
   * @param answers  
   * @returns {boolean} if the question is going be displayed.
   */
  checkIfQuestionDisplay(path, question, answers) {
    const _self = this
    const showIfConditions = question.showIf
    if (showIfConditions && showIfConditions.length !== 0 && !_self.props.ignoreShowIf) {
      return showIfConditions.reduce((prev, condition) => {
        const targetValue = condition.value
        let targetVariable = condition.variable
        let dominatePaths
        if (Array.isArray(condition.variable)) {
          targetVariable = condition.variable[condition.variable.length - 1]
          dominatePaths = [condition.variable.slice(0, condition.variable.length - 1)]
        } else {
          dominatePaths = this.getDominantPath(path)
        }
        const isSatisfied = dominatePaths.reduce((prev, curr) => {
          const checkedAnswer = this.getAnswerWithPath(curr, targetVariable, answers).value
          let isEqual
          if (condition.comparator && checkedAnswer) {
            let compareValueWithFormat = checkedAnswer
            let targetValueWithFormat = targetValue
            if (condition.formatter) {
              if (condition.formatter === 'AGE') {
                compareValueWithFormat = moment().diff(moment(checkedAnswer), 'years')
              } else if (condition.formatter === 'DATE') {
                compareValueWithFormat = moment(checkedAnswer).toDate()
                targetValueWithFormat = moment(targetValue).toDate()
              }
              //TODO more formatter can added in later
            }
            if (condition.comparator === 'GREATER_THAN') {
              isEqual = compareValueWithFormat > targetValueWithFormat
            } else if (condition.comparator === 'LESS_THAN') {
              isEqual = compareValueWithFormat < targetValueWithFormat
            } else if (condition.comparator === 'GREATER_THAN_AND_LESS_THAN') {
              let splitTarget = targetValueWithFormat.split(' ')
              let targetGreaterThan = splitTarget[0], targetLesserThan = splitTarget[1]
              isEqual = (targetGreaterThan < compareValueWithFormat) && (targetLesserThan > compareValueWithFormat)
            }
          } else if (condition.notEmpty) {
            const error = _self.validateAnswer(question.validation, checkedAnswer)
            isEqual = !!(!error && checkedAnswer)
          } else if (condition.notCollapse && Array.isArray(condition.notCollapse)) {
            // hide question set when target question is collapsed
            try {
              const length = condition.notCollapse.length
              const targetPath = condition.notCollapse.slice(0, length - 1)
              const targetVariable = condition.notCollapse.slice(length - 1, length)
              const value = _self.getAnswerWithPath(targetPath, targetVariable, answers)
              isEqual = !value.isCollapsed
            } catch (err) {
              // pass
            }
          } else {
            // multiple answers are stored in an array
            if (Array.isArray(checkedAnswer)) {
              isEqual = checkedAnswer.indexOf(targetValue) > -1
            } else {
              isEqual = checkedAnswer === targetValue
            }
          }
          return prev || isEqual
        }, false)
        // let actualValue = this.getAnswerWithPath(path, targetVariable);
        return prev || isSatisfied
      }, false)
    }
    return true
  }

  /**
   * Check if newly updated answer fit any business rule
   * @param {Array} path path to answer
   * @param {Object} q current question
   * @param {Object} questionSetInAnswer
   */
  checkBusinessRule(path, q, questionSetInAnswer, answers) {
    const _self = this

    const variable = q.variable
    const ifQuestionDisplay = this.checkIfQuestionDisplay(path, q)
    if (q.businessRule) {
      const businessRule = q.businessRule
      if (businessRule.rule === QS_CONSTANTS.MULTIPLICATION) {
        const values = businessRule.reference.map(eachVariable => parseInt(this.getAnswerWithPath(path, eachVariable)))
        let result = values.reduce((a, b) => a * b)
        if (isNaN(result)) {
          result = 0
        }
        questionSetInAnswer[q.variable] = {
          value: result
        }
      }
    }
    // this part handles when a question's status changed from 'show' to 'hidden' because of showIf rule.
    // it's previous value should be set back to ''.
    // comment this out to prevent reset none 'resetAnswerIfHidden' field
    if (q.showIf) {
      // if (!ifQuestionDisplay && !isFirstInit) {
      //   questionSetInAnswer[variable] = {
      //     value: ''
      //   }
      //   delete this.progress[path + variable]
      // } else {
      // if variable needs to be displayed but there is no answer record, add it.
      if (Object.keys(questionSetInAnswer).indexOf(variable) < 0) {
        if (q.inputType === QS_CONSTANTS.INPUT_TYPE.CHECKBOX) {
          questionSetInAnswer[q.variable] = {
            value: []
          }
        } else {
          questionSetInAnswer[q.variable] = {
            value: ''
          }
        }
      }
      // }
    }
    if (q.formula && ifQuestionDisplay) {
      const { formula, timeCalculate } = q
      const value = _self.getAnswerWithPath(path, q.variable)
      let isValuesReady = true
      if (!value.isCollapsed) {
        const variablePaths = _self.getFormulaVariables(formula)
        const values = _.transform(variablePaths, (result, path) => {
          const variableValue = _self.getFormulaVariableValue(path, answers)
          if (timeCalculate) {
            const value = _.isObject(variableValue) ? moment(variableValue.value, QS_CONSTANTS.DEFAULT_TIME_FORMAT) : null
            if (!value || !value.isValid()) {
              isValuesReady = false
              return false // break transform
            }
            result[path] = value.unix()
          } else {
            const value = _.isObject(variableValue) ? Number(variableValue.value) : 0
            result[path] = _.isNaN(value) ? 0 : value
            return result
          }
        }, {})
        if (isValuesReady) {
          const result = _self.getFormulaResult(formula, values)
          if (timeCalculate) {
            // add one more day if two time diff is less than 0
            const timeValue = moment.duration(result > 0 ? result : result + 60 * 60 * 24, 'seconds').format(timeCalculate.format, {
              trim: false
            })
            if (timeValue !== value) {
              questionSetInAnswer[q.variable] = {
                ...questionSetInAnswer[q.variable],
                value: timeValue
              }
            }
            return
          }
          const stringResult = result.toString()
          if (stringResult !== value) {
            questionSetInAnswer[q.variable] = {
              ...questionSetInAnswer[q.variable],
              value: stringResult
            }
          }
        }
      }
    }
  }


  /**
   * Handle component mounted event. Initialize the study answer state.
   * Initial the answers state when :
   * This study has never been answered. (no answer record in study record.)
   */
  componentDidMount() {
    const answersClone = _.cloneDeep(this.props.answers)
    this.initialAnswerObject(answersClone, true)
    if (!_.isEqual(answersClone, this.props.answers)) {
      this.sendAnswerDataToReduxStore(answersClone)
    }
    this.dispatchProgress()
  }

  /**
   * React Life Cycle function. triggered every time the component got updated.
   * @param {Object} prevProps Props of the previous component.
   * @param {Object} prevState State of the previous component.
   */
  componentDidUpdate(prevProps) {
    const answersClone = _.cloneDeep(this.props.answers)
    this.initialAnswerObject(answersClone)
    if (!_.isEqual(prevProps.answers, answersClone)) {
      this.props.onAnswersChange(answersClone)
    }
    this.reloadProgress(prevProps.answers)
  }

  isInitialAnswersNeeded(studyProfile, answers) {
    if (studyProfile && studyProfile.uuid) {
      if (!answers || Object.keys(answers).length === 0) {
        return true
      }
      return false
    }
    return false
  }

  /**
   * Handle component will receive props life cycle event.
   * Initialize answers when there is answers record in redux.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const isInitialAnswersNeeded = this.isInitialAnswersNeeded(nextProps.studyProfile, nextProps.answers)
    if (isInitialAnswersNeeded) {
      const answers = {}
      this.initialAnswerObject(answers)
      this.sendAnswerDataToReduxStore(answers)
    }
  }
  /**
   * Update progress record in redux store.
   * 1. construct progress object,
   * 2. send progress object to redux.
   */
  dispatchProgress() {
    const progressToDispatch = {}
    const progress = Object.assign({}, this.progress)
    Object.keys(progress).forEach((eachProgressKey) => {
      const setName = eachProgressKey.split(',')[0]
      const { isRequired, value, isValid, ignore } = progress[eachProgressKey]
      if (ignore) {
        return
      }
      if (Object.keys(progressToDispatch).indexOf(setName) < 0) {
        progressToDispatch[setName] = {
          total: 0,
          totalRequired: 0,
          answered: 0,
          answeredRequired: 0,
          invalid: 0
        }
      }
      // update total quesiton num
      progressToDispatch[setName].total++
      // update total answered num
      if (value.value !== '' && value.value !== [] && isValid) {
        progressToDispatch[setName].answered++
      }
      // update total required num
      if (isRequired) {
        progressToDispatch[setName].totalRequired++
        // update required answer num
        if (value.value !== '' && value.value !== [] && isValid) {
          progressToDispatch[setName].answeredRequired++
        }
      }

      if (!isValid) {
        progressToDispatch[setName].invalid++
      }
    })
    if (!_.isEqual(progressToDispatch, this.props.answerProgress) && this.props.onProgressChange) {
      this.props.onProgressChange(progressToDispatch)
    }
  }
  /**
   * filter the vocabulary to find the corresponding options for a question
   * @param {String} optionsID: The ID of a set of vocabularies
   * @return {Array} An array of options.
   */
  findOptionsInVocabulary(optionsID) {
    if (this.props.vocabularies) {
      const options = this.props.vocabularies.filter(vocabulary => vocabulary._id === optionsID)
      if (options.length > 0) {
        return options[0].values
      }
      return []
    }
    return []
  }

  /**
   * Get the answer in object through the path
   * @param {Array} path: the path to the the answer.
   * @param {String} variable: the name of the variable.
   * e.g ['population', 1, 'result'] leads to answer[population][1][result]
   */
  getAnswerWithPath(path, variable, answers) {
    answers = answers || this.props.answers
    let answer = null
    try {
      answer = path.reduce((o, i) => o[i], answers)
    } catch (e) {
      return {
        value: ''
      }
    }
    return answer ? answer[variable] ? answer[variable] : { value: '' } : { value: '' }
  }
  getQuestionSetAnswers(path, answers) {
    answers = answers || this.props.answers
    let answer = null
    try {
      answer = path.reduce((o, i) => o[i], answers)
    } catch (e) {
      return {
        value: ''
      }
    }
    return answer ? answer : {}
  }
  /**
   * Retrieve all possible question set's layers that can affect showIf condition.
   * @param {Array} path path to affected question.
   * (Only direct ancestors' value can dominate the value of the children. Nodes on the same level can not affect current value. )
   */
  getDominantPath(path) {
    const dominantPaths = []
    const currentPath = _.cloneDeep(path)
    let i = path.length
    for (i; i > 0; i--) {
      const pos = i - 1
      if (typeof currentPath[pos] !== 'undefined') {
        if (typeof currentPath[pos] === 'number') {
          dominantPaths.push([...currentPath])
          currentPath.splice(-2, 2)
        } else {
          dominantPaths.push([...currentPath])
          currentPath.splice(-1, 1)
        }
      }
    }
    // support cross question set (only first layer.)
    this.props.questionSet.questions.forEach((questionSet) => { dominantPaths.push([questionSet.name]) })
    return dominantPaths
  }

  getFormulaVariables(formula) {
    const regex = /'((?!'}).*?)'/g
    const variables = formula.match(regex).map(el => el.replaceAll('\'', ''))
    return variables
  }

  getFormulaVariableValue(path, answers) {
    const _self = this
    answers = answers || _self.props.answers
    return _.get(answers, path)
  }

  getFormulaResult(formula, values) {
    const _self = this
    let result = formula
    const variables = _self.getFormulaVariables(formula)
    variables.forEach(variable => {
      result = result.replace('${\'' + variable + '\'}', values[variable])
    })
    return evaluate(result)
  }

  /**
   * Get dropdown options from question object.
   * @param {Object} question Question to be displayed.
   * @param {Array} path Path to current question set.
   * @returns {String} the ID of the options that is going to be displayed.
   */
  getOption(question, path) {
    const businessRule = question.businessRule
    if (businessRule && businessRule.rule === QS_CONSTANTS.CHANGE_OPTIONS) {
      const rules = businessRule.changeRules
      let changeToOption = null
      rules.forEach((rule) => {
        const targetVariable = rule.variable
        const targetValue = rule.value
        const actualValue = this.getAnswerWithPath(path, targetVariable)
        if (Array.isArray(actualValue)) {
          if (actualValue.indexOf(targetValue) > -1) {
            changeToOption = rule.options
          }
        } else if (targetValue === actualValue) {
          changeToOption = rule.options
        }
      })
      if (changeToOption) {
        return changeToOption
      }
      return question.options
    }
    return question.options
  }

  /**
   * Get radio option's label and value
   * @param {Object|string} option
   * @param {string} option.label
   * @param {string|number} option.value
   * @return {Object} The label and value of input option
   */
  getOptionDetails(option) {
    let optionLabel
    let optionValue
    let optionEnableTextInput = false
    let optionTextInputPlaceholder
    if (_.isObject(option)) {
      optionLabel = option.label
      optionValue = option.value
      optionEnableTextInput = option.enableTextInput
      optionTextInputPlaceholder = option.textInputPlaceholder
    } else {
      optionValue = optionLabel = option
    }
    return {
      optionLabel,
      optionValue,
      optionEnableTextInput,
      optionTextInputPlaceholder
    }
  }

  /**
   * get a list of options and map them into a option object that can be used by React-select
   * @param {String} optionUUID
   * @returns {Array} List of Option Object {label: xxx, value: xxx}
   */
  getQuestionSetsOptions(optionUUID) {
    const options = this.findOptionsInVocabulary(optionUUID)
    const newOptions = options.map(each => ({ label: each, value: each }))
    return newOptions
  }
  /**
   * get the answer of a repeated set rule's reference.
   * @param answers {Object} ansewr object, copy from current state.
   * @param path {Array} path to current questioin set in answer object.
   * @param ref {String} the variable name of variable which decide the time of rendering.
   * @returns {number}
   */
  getRefAnswer(answers, path, ref) {
    // dominantPaths are the paths to any nodes that can affect current question set's rendering.
    const paths = this.getDominantPath(path)
    let num = 0
    paths.forEach((path) => {
      const answer = this.getAnswerWithPath(path, ref)
      if (!isNaN(parseInt(answer))) {
        num = parseInt(answer)
      }
    })
    return num
  }
  /**
   * Handle form change event. it does following things
   * 1. update value change to the state (user answers a question)
   * 2. check business rule in setState callback. if the change fit any rule
   * 3. apply business rule,
   * 4. check if a text area needs to be appear or hidden.
   * 5. update the progress of the data input tin redux store.
   *
   * @param {Object} evt object
   * @param {String} variable name
   * @param {String} questionSetName question set name
   * @param {String} inputType
   * @param {Object} questionSet
   */
  handleChange(evt, variable, questionSetName, inputType, questionSet, question, path, isForce) {
    if (inputType === QS_CONSTANTS.INPUT_TYPE.TEXT && !this.isPassPercentValidation(evt.target.value, question)) {
      return
    }
    if (!isForce) {
      const { touchedQuestionPath } = this.state
      const touchedPath = `${path.toString()},${variable}`
      if (!touchedQuestionPath.includes(touchedPath)) {
        touchedQuestionPath.push(touchedPath)
        this.setState({ touchedQuestionPath })
      }
    }
    // update the value of the target input field.
    let newAnswer = {}
    if (inputType === QS_CONSTANTS.INPUT_TYPE.SLIDER) {
      newAnswer = this.setSliderAnswer(evt.target.name, evt.target.value, path)
    } else {
      const value = evt.target.value
      newAnswer = this.setAnswer(evt.target.name, value, path)
      /**
       * Sync questions answer once changed
       */
      if (question.syncAnswerWith && Array.isArray(question.syncAnswerWith)) {
        question.syncAnswerWith.forEach(target => {
          const name = target[target.length - 1]
          const path = target.slice(0, target.length - 1)
          newAnswer = this.setAnswer(name, value, path, newAnswer)
        })
      }
    }
    this.initialAnswerObject(newAnswer)
    this.props.onAnswersChange(newAnswer)
  }
  /**
   * Handle react-select form change before real handleChange() as React-select does not provide evt object.
   * @param {Object} selectedOption object: {label:xxx, value: xxx}
   * @param {String} variable name
   * @param {String} questionSetName question set name
   * @param {String} inputType
   * @param {Object} questionSet
   * @param {question} question
   * @param {Array} path
   */
  handleSelect(selectedOption, variable, questionSetName, inputType, questionSet, question, path) {
    const evt = {}
    evt.target = {}
    evt.target.value = selectedOption ? selectedOption.value : ''
    evt.target.name = variable
    this.handleChange(evt, variable, questionSetName, inputType, questionSet, question, path)
  }

  /**
   * Handle react-select form change before real handleChange() as React-select does not provide evt object.
   * @param {Object} selectedOption object: {label:xxx, value: xxx}
   * @param {String} variable name
   * @param {String} questionSetName question set name
   * @param {String} inputType
   * @param {Object} questionSet
   * @param {question} question
   * @param {Array} path
   */
  handleSliderChange(selectedOption, variable, questionSetName, inputType, questionSet, question, path) {
    const evt = {}
    evt.target = {}
    evt.target.value = selectedOption || 0
    evt.target.name = variable
    this.handleChange(evt, variable, questionSetName, inputType, questionSet, question, path)
  }
  /**
   * HandleCheckboxInput before handleChange
   * @param option
   * @param optionsID
   * @param variable
   * @param questionSetName
   * @param inputType
   * @param questionSet
   * @param question
   * @param path
   */
  handleCheckboxInput(option, optionsID, variable, questionSetName, inputType, questionSet, question, path) {
    const NewEvt = {}
    NewEvt.target = {}
    NewEvt.target.value = option
    NewEvt.target.name = variable
    this.handleChange(NewEvt, variable, questionSetName, inputType, questionSet, question, path)
  }
  /**
   * Handle date picker input
   * @param option
   * @param variable
   * @param questionSetName
   * @param inputType
   * @param questionSet
   * @param question
   * @param path
   */
  handleDatePickerChange(option, variable, questionSetName, inputType, questionSet, question, path) {
    const NewEvt = {}
    NewEvt.target = {}
    NewEvt.target.value = option
    NewEvt.target.name = variable
    this.handleChange(NewEvt, variable, questionSetName, inputType, questionSet, question, path)
  }

  /**
   * Handle time picker input
   * @param time
   * @param variable
   * @param questionSetName
   * @param inputType
   * @param questionSet
   * @param question
   * @param path
   */
  handleTimePickerChange(option, variable, questionSetName, inputType, questionSet, question, path) {
    const NewEvt = {}
    NewEvt.target = {}
    NewEvt.target.value = option
    NewEvt.target.name = variable
    this.handleChange(NewEvt, variable, questionSetName, inputType, questionSet, question, path)
  }

  /**
   * Handle Radio-select change before real handleChange() as React-select does not provide evt object.
   * @param {String} option
   * @param {String} optionsID
   * @param {String} variable
   * @param {String} questionSetName
   * @param {String} inputType
   * @param {Object} questionSet
   * @param {Object} question
   * @param {Array} path
   */
  handleRadioSelect(option, optionsID, variable, questionSetName, inputType, questionSet, question, path) {
    const NewEvt = {}
    NewEvt.target = {}
    NewEvt.target.value = option
    NewEvt.target.name = variable
    this.handleChange(NewEvt, variable, questionSetName, inputType, questionSet, question, path)
  }

  validateAnswer(validation, answer) {
    const { value } = answer
    const { isRequired, maxLength, minLength, number } = validation
    const isMaxLengthRequired = maxLength.required
    const maxLengthValue = (maxLength.value && maxLength.value !== '') ? Number(maxLength.value) : null
    const isMinLengthRequired = minLength.required
    const minLengthValue = (minLength.value && minLength.value !== '') ? Number(minLength.value) : null
    const isNumberValidationRequired = number.required
    const minNumber = (number.minValue !== undefined && number.minValue !== '' && !isNaN(Number(number.minValue))) ? Number(number.minValue) : null
    const maxNumber = (number.maxValue !== undefined && number.maxValue !== '' && !isNaN(Number(number.minValue))) ? Number(number.maxValue) : null
    const decimalPlaces = (number.decimalPlaces !== undefined && number.decimalPlaces !== '' && !isNaN(Number(number.decimalPlaces))) ? Number(number.decimalPlaces) : null
    let error
    if (isRequired && (value === '' || (Array.isArray(value) && value.length === 0))) {
      error = ' IS A REQUIRED FIELD.'
    } else if (!(value === '' || (Array.isArray(value) && value.length === 0))) {
      if (isMaxLengthRequired && maxLengthValue && value.length > maxLengthValue) {
        error = ` MAX LENGTH IS ${maxLengthValue}`
      } else if (isMinLengthRequired && minLengthValue && value.length < minLengthValue) {
        error = ` MIN LENGTH IS ${minLengthValue}`
      } else if (isNumberValidationRequired) {
        if (isNaN(Number(value))) {
          error = ' SHOULD BE NUMBER'
        } else if (minNumber !== null && Number(value) < minNumber) {
          error = ` SHOULD BE GREATER THAN OR EQUAL TO ${minNumber}`
        } else if (maxNumber !== null && Number(value) > maxNumber) {
          error = ` SHOULD BE LESS THAN OR EQUAL TO ${maxNumber}`
        } else if (decimalPlaces && value !== Number(value).toFixed(decimalPlaces)) {
          error = ` SHOULD BE ${decimalPlaces} DECIMAL PLACES`
        }
      }
    }
    return error
  }

  /**
   * validate if a give value fits the percent validation
   * @param value
   * @param question
   * @returns {boolean}
   */
  isPassPercentValidation(value, question) {
    if (question.validation && question.validation.percent && question.validation.percent.required) {
      const max = 100
      const min = 0
      return this.isWithBoundary(max, min, value)
    }
    return true
  }
  /**
   * Check if give value is within a given boundary. return false to indicates it is not.
   * @param {Number} max
   * @param {Number} min
   * @param {String} value
   * @returns {boolean}
   */
  isWithBoundary(max, min, value) {
    return !(isNaN(value) || Number(value) < min || Number(value) > max)
  }
  /**
   * Initialize question to ''
   *            question set to {}
   *            repeated question set to []
   * @param {Object} questionSetInAnswer answer object of question set
   * @param {Object} q current question
   */
  initialAnswer(questionSetInAnswer, q) {
    const _self = this
    if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION) {
      if (typeof questionSetInAnswer[q.variable] === 'undefined') {
        if (Object.prototype.hasOwnProperty.call(q, 'defaultValue') && !_self.props.fieldDisabled) {
          if (typeof q.defaultValue === 'object') {
            const { type } = q.defaultValue
            switch (type) {
              case QS_CONSTANTS.INPUT_TYPE.TIME:
                if (q.defaultValue.currentTime) {
                  questionSetInAnswer[q.variable] = {
                    value: moment().format(QS_CONSTANTS.DEFAULT_TIME_FORMAT),
                  }
                }
                break
              case QS_CONSTANTS.INPUT_TYPE.DATE:
                if (q.defaultValue.currentDate) {
                  questionSetInAnswer[q.variable] = {
                    value: moment().format(QS_CONSTANTS.DEFAULT_DATE_FORMAT),
                  }
                }
                break
              default:
                break
            }
            if (typeof questionSetInAnswer[q.variable] !== 'undefined') {
              return
            }
          }
          questionSetInAnswer[q.variable] = {
            value: q.defaultValue,
          }
          return
        }
        if (q.inputType === QS_CONSTANTS.INPUT_TYPE.CHECKBOX) {
          questionSetInAnswer[q.variable] = {
            value: [],
          }
        } else {
          questionSetInAnswer[q.variable] = {
            value: ''
          }
        }
      } else if ((typeof questionSetInAnswer[q.variable] === 'string') &&
        (q.inputType === QS_CONSTANTS.INPUT_TYPE.CHECKBOX)) {
        // Handle the situation that admin change the input type from single slection to mulitple choice
        questionSetInAnswer[q.variable] = {
          value: []
        }
      }
    } else if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION_SET) {
      if (q.display === QS_CONSTANTS.REPEAT_SET) {
        if (!questionSetInAnswer[q.name]) {
          questionSetInAnswer[q.name] = []
        }
      } else if (!questionSetInAnswer[q.name]) {
        questionSetInAnswer[q.name] = {}
      }
    }
  }
  /**
   * Build / update Answer tree. initialize Answer object with question set object and its path to the answer.
   * Each element in the openList is a tuple of question object (or a question set object) with its path to answers.
   * they two together help us to locate the answer object that we are going to to operate
   * @param answers {Object} answer object (state)
   */
  initialAnswerObject(answers) {
    const _self = this
    _self.progress = {}
    _self.validations = {}
    const questionSet = this.props.questionSet
    const collapseQuestions = []
    const calculateAgeQuestions = []
    const openList = []
    const postInitialAnswerActions = []
    questionSet.questions.forEach((each) => { openList.push([each, []]) })
    while (openList.length !== 0) {
      // q: question, could either be a question or a question set
      // path: the path to the answer of this question/set.
      const [q, path] = openList.pop()
      if (q.canFormulaCollapse) {
        collapseQuestions.push([q, path])
      }
      if (Object.prototype.hasOwnProperty.call(q, 'calculateAgeBasedOn')) {
        calculateAgeQuestions.push([q, path])
      }
      // questionSetInAnswer => Answer object of a question set in the answer tree.
      // it looks like { variable1: answer1 , variable2: answer2, sub-repeated-question-set: [ {sub-variable: xx}, {sub-variable: xx}, {sub-variable3: xx}]}
      let questionSetInAnswer = path.reduce((k, v) => k[v], answers)
      let questionSetInValidation = path.reduce((k, v) => k[v], _self.validations)
      if (!questionSetInAnswer) {
        answers[q.name] = {}
        questionSetInAnswer = answers[q.name]
      }
      if (!questionSetInValidation) {
        _self.validations[q.name] = {}
        questionSetInValidation = _self.validations[q.name]
      }
      // initial answers when there is no answer record in answers. change {} to {variable1: 'None', variable2: 'None'}
      this.initialAnswer(questionSetInAnswer, q)
      if (q.type && q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION_SET) {
        questionSetInValidation[q.name] = {}
        if (q.display && q.display === QS_CONSTANTS.REPEAT_SET) {
          // function below helps to check if we need add/ remove a sub question set from answer object.
          // for example: NUM_POP = 4 and it controls the repeat num of a question set 'sub-pop' within question set A,
          // at the same time, we only have A: {sub-pop:[ {sub1}, {sub2} ]} for answer object. Then we need add two more
          // answer object for the repeated question set. finally we will have A: {sub-pop: [{sub1}, {sub2}, {sub3}, {sub4}]}
          this.initialAnswerOfRepeatedQuestionSet(q, path, questionSetInAnswer)
          const numShouldRepeat = this.getRefAnswer(answers, path, q.reference)
          // push sub-question set with its path to openList.
          q.questions.forEach((question) => {
            _.range(0, numShouldRepeat).forEach((k) => {
              const newPath = [...path]
              newPath.push(q.name)
              newPath.push(k)
              openList.push([question, newPath])
            })
          })
        } else {
          q.questions.forEach((question) => {
            const newPath = [...path]
            newPath.push(q.name)
            openList.push([question, newPath])
          })
        }
        if (q.resetAnswersIfHidden) {
          const ifQuestionSetDisplay = _self.checkIfQuestionDisplay(path, q, answers)
          if (!ifQuestionSetDisplay) {
            const resetAnswer = (path, q, answers) => {
              if (!q.type) return
              if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION_SET) {
                const newPath = [...path, q.name]
                q.questions.forEach(q => {
                  resetAnswer(newPath, q, answers)
                })
              } else if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION) {
                _self.setAnswer(q.variable, { value: ''}, path, answers, true)
              }
            }
            resetAnswer(path, q, answers)
          }
        }
      } else if (q.type && q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION) {
        // check business rule.
        this.checkBusinessRule(path, q, questionSetInAnswer, answers)
        // validation
        if (q.validation) {
          let answer = questionSetInAnswer[q.variable]
          if (_self.state.touchedQuestionPath.includes(`${path.toString()},${q.variable}`)) {
            questionSetInValidation[q.variable] = _self.validateAnswer(q.validation, answer)
          }
        }
        _self.updateProgress(path, q, questionSetInValidation, questionSetInAnswer)
      }
    }
    collapseQuestions.forEach(([question, path]) => {
      const value = _self.getAnswerWithPath(path, question.variable, answers)
      if (!Object.prototype.hasOwnProperty.call(value, 'isCollapsed')) {
        const newVal = _.cloneDeep(value)
        const variablePaths = _self.getFormulaVariables(question.formula)
        variablePaths.forEach(path => {
          const answer = _.get(answers, path)
          answer.isHidden = false
          answers = _.set(answers, path, answer)
        })
        newVal.isCollapsed = false
        answers = _.set(answers, [...path, question.variable], newVal)
      }
    })
    calculateAgeQuestions.forEach(([question, path]) => {
      const answer = _self.getAnswerWithPath(path, question.variable, answers)
      const length = question.calculateAgeBasedOn.length
      const targetPath = question.calculateAgeBasedOn.slice(0, length - 1)
      const targetVariable = question.calculateAgeBasedOn.slice(length - 1, length)
      const targetValue = _self.getAnswerWithPath(targetPath, targetVariable, answers).value
      if (targetValue !== undefined && targetValue !== '') {
        try {
          const date = moment(targetValue, 'DD/MM/YYYY')
          if (date.isValid()) {
            const createdAt = answers.CREATED_AT

            let age
            if (createdAt) {
              age = moment(createdAt).diff(date, 'years')
            } else {
              age = moment().diff(date, 'years')
            }
            answers = _.set(answers, [...path, question.variable], { ...answer, value: age })
          }
        } catch (err) {
          // pass
        }
      }
    })
    if (!answers.CREATED_AT) {
      answers.CREATED_AT = Date.now()
    }
    _self.postInitialAnswerObject(postInitialAnswerActions)
  }

  postInitialAnswerObject(actions) {
    for (const action of actions) {
      action()
    }
  }
  /**
   * This function is used to deal with the situation that user add/remove a repeated qeustion set. we need to initialize it
   * @param {Object} q current question set
   * @param {Array} path the path to the question set in answer object.
   * @param {Object} questionSetInAnswer the question set object in answer.
   */
  initialAnswerOfRepeatedQuestionSet(q, path, questionSetInAnswer) {
    const repeatedSetName = q.name
    const numShouldRepeat = this.getRefAnswer(this.state, path, q.reference)
    const currentRepeatAnswerObj = questionSetInAnswer[repeatedSetName] // could be [] or [obj,obj]
    const currentLength = questionSetInAnswer[repeatedSetName].length
    // push a new question set to parent set when there is more repeated question set to be rendered.
    if (currentLength < numShouldRepeat) {
      const repeatedSetAnswerInitialized = this.initializeRepeatedSet(q)
      _.range(0, numShouldRepeat - currentLength).forEach(() => {
        currentRepeatAnswerObj.push(_.cloneDeep(repeatedSetAnswerInitialized))
      })
    } else if (currentLength > numShouldRepeat) {
      // remove a question set from repeated set.
      _.range(0, currentLength - numShouldRepeat).forEach(() => {
        currentRepeatAnswerObj.pop()
      })
    }
  }
  /**
   * When user input an answer which can result in rendering more question set after it, an object should be initialized
   * with an empty array [] to store values within it.
   * @param {Object} questionSet
   * @returns {Object} Answer object (updated);
   */
  initializeRepeatedSet(questionSet) {
    const answerObj = {}
    questionSet.questions.forEach((question) => {
      if (question.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION) {
        answerObj[question.variable] = ''
      } else if (question.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION_SET) {
        answerObj[question.name] = []
      }
    })
    return answerObj
  }

  /**
   * Determine ff a question is required to be answered before form being submitted.
   * @param q {Object} question object
   * @returns {boolean} if an answer to the question is required.
   */
  isQuestionRequired(q) {
    return q.validation && q.validation.isRequired === true
  }

  /**
   *
   * @param variable
   * @returns {boolean}
   */
  isTextAreaVisible(variable, path) {
    const answer = this.getAnswerWithPath(path, variable)
    return answer === QS_CONSTANTS.OTHER_ANSWER
  }

  /**
   * Return orderal suffix e.g. 1 = 1st, 2 = 2nd
   * @param i
   * @returns {string}
   */
  getOrdinalPrefix(i) {
    var j = i % 10,
      k = i % 100
    if (j === 1 && k !== 11) {
      return i + 'st'
    }
    if (j === 2 && k !== 12) {
      return i + 'nd'
    }
    if (j === 3 && k !== 13) {
      return i + 'rd'
    }
    return i + 'th'
  }

  /**
   * Return the section initial, e.g. first section is 'A. ', 26th section is 'Z' 27th section is 'AA. '.
   * @param index
   * @returns {*}
   */
  getSectionLable(index) {
    let a = index + 1
    let initials = []
    while (a > 26) {
      initials.push(a % 26)
      a = Math.floor(a / 26)
    }
    initials.push(a)
    initials = initials.reverse()
    const alphabet = [...Array(26).keys()].map(i => String.fromCharCode(i + 97).toUpperCase())
    let label = ''
    initials.forEach((initial) => {
      label += alphabet[initial - 1]
    })
    return label
  }

  /**
   * Return the sub section initial, e.g. first section is '1. ', second is '2. '.
   * @param index
   * @returns {*}
   */
  getSubSectionLabel(index) {
    return index + 1
  }


  reloadProgress(prevAnswer) {
    const prevAnswerClone = _.cloneDeep(prevAnswer)
    if (!_.isEqual(prevAnswerClone, this.props.answers)) {
      this.dispatchProgress()
    }
  }

  /**
   * Render the component.
   * @returns {XML}
   */
  render() {
    const { handleSubmit, fieldDisabled, questionSet } = this.props
    return (
      <div className="dynamic-form">
        <form id={this.props.name} name={this.props.name} onSubmit={handleSubmit} className="questions">
          {questionSet &&
            questionSet.questions &&
            questionSet.questions.map((questionSet, idx1) => {
              if (this.props.currentSectionIndex === idx1) {
                const path = [questionSet.name]
                return this.renderQuestionSet(idx1, path, questionSet, fieldDisabled)
              }
              return null
            })
          }
        </form>
      </div>
    )
  }

  /**
   * Handler to display tooltip when the slider value changes.
   * @param props
   * @returns {*}
   */
  handleSliderToolTip = (props) => {
    const { value, dragging, index, ...restProps } = props
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    )
  };

  /**
   * Render a Select Input Question.
   * @param {Object} qs Question set
   * @param {Object} question Question
   * @param {String} key Element key
   * @param {Array} path Path to answer object
   * @param fieldDisabled - 0/1
   * @returns {XML}
   */
  renderSlider({ qs, question, value, path, disabled }) {
    const variable = question.variable
    value = parseInt(value)

    let minLength = 0, maxLength = 100
    let sliderOptions = question.sliderOptions
    let step = sliderOptions.step || 1, dots = sliderOptions.dots || false
    let leftValue = null, rightValue = null

    // Extract min and max value
    if (Array.isArray(sliderOptions.minMax) && (sliderOptions.minMax.length === 2)) {
      minLength = sliderOptions.minMax[0]
      maxLength = sliderOptions.minMax[1]
    }

    // Extract values to be displayed at the left and right of the slider
    if (Array.isArray(sliderOptions.extremeValues) && (sliderOptions.extremeValues.length === 2)) {
      leftValue = sliderOptions.extremeValues[0]
      rightValue = sliderOptions.extremeValues[1]
    }

    return (
      <div className='slider__outer_div'>
        {
          leftValue || rightValue
            ?
            <div className='slider__extreme_value_div'>
              <span className='slider__extreme_value_left'>{leftValue}</span>
              <span className='slider__extreme_value_right'>{rightValue}</span>
            </div>
            :
            <p className='slider__value_display'>{value || 0}</p>
        }

        <Slider min={minLength} max={maxLength}
          defaultValue={value || 0}
          handle={this.handleSliderToolTip}
          name={variable}
          step={step}
          dots={dots}
          disabled={disabled}
          onChange={v => {
            this.handleSliderChange(v, variable, qs.name, QS_CONSTANTS.INPUT_TYPE.SLIDER, qs, question, path)
          }
          }
        />
      </div>
    )
  }
  /**
   * Render a Select Input Question.
   * @param {Object} qs Question set
   * @param {Object} question Question
   * @param {String} key Element key
   * @param {Array} path Path to answer object
   * @param {String} value Answer of the question.
   * @returns {XML}
   */
  renderSelectInput({ qs, question, value, path, disabled }) {
    const { SelectInput } = this.components

    const _self = this
    const variable = question.variable
    const optionsID = _self.getOption(question, path)
    const options = _self.findOptionsInVocabulary(optionsID).map(option => _self.getOptionDetails(option))
    const onChange = evt => _self.handleChange(evt, variable, qs.name, QS_CONSTANTS.INPUT_TYPE.SELECT, qs, question, path)
    return (
      <SelectInput
        disabled={disabled}
        onChange={onChange}
        options={options}
        value={value}
        variable={variable}
      />
    )
  }
  /**
   * Render a Text Input Question.
   * @param {Object} qs Question set
   * @param {Object} question Question
   * @param {String} key Element key
   * @param {Array} path Path to answer object
   * @returns {XML}
   */
  renderTextInput({ qs, question, value, path, disabled }) {
    const { TextInput } = this.components

    const _self = this
    const { variable, canFormulaCollapse } = question
    const isFormula = Object.prototype.hasOwnProperty.call(question, 'formula')
    const isCalculateAge = Object.prototype.hasOwnProperty.call(question, 'calculateAgeBasedOn')
    const componentDisabled = (isFormula && !value.isCollapsed) || disabled || isCalculateAge
    const collapseQuestion = e => {
      e.preventDefault()
      let answers = _.cloneDeep(_self.props.answers)
      const newVal = _.cloneDeep(value)
      const variablePaths = _self.getFormulaVariables(question.formula)
      const values = {}
      variablePaths.forEach(path => {
        const answer = _.get(answers, path)
        const value = _.isObject(answer) ? Number(answer.value) : 0
        values[path] = _.isNaN(value) ? 0 : value
        answer.isHidden = !newVal.isCollapsed
        answers = _.set(answers, path, answer)
      })
      newVal.isCollapsed = !newVal.isCollapsed
      if (!newVal.isCollapsed) {
        newVal.value = _self.getFormulaResult(question.formula, values).toString()
      }
      answers = _.set(answers, [...path, question.variable], newVal)
      _self.initialAnswerObject(answers)
      _self.props.onAnswersChange(answers)
    }

    return (
      <>
        <TextInput
          disabled={componentDisabled}
          name={variable}
          onChange={e => _self.handleChange(e, variable, qs.name, QS_CONSTANTS.INPUT_TYPE.TEXT, qs, question, path)}
          value={value}
          question={question}
        />
        {
          isFormula && canFormulaCollapse
            ?
            <button
              className={classNames({
                textInput__collapseBtn: true
              })}
              onClick={collapseQuestion}
            >
              {value.isCollapsed ? 'Expand' : 'Collapse'} sub-questions
            </button>
            :
            null
        }
      </>
    )
  }

  /**
   * Render a Text Area Question.
   * @param {Object} qs Question set
   * @param {Object} question Question
   * @param {String} key Element key
   * @param {Array} path Path to answer object
   * @returns {XML}
   */
  renderTextArea({ qs, question, value, path, disabled }) {
    const { TextArea } = this.components

    const _self = this
    const { variable } = question
    const isFormula = question.formula
    const componentDisabled = (isFormula && !value.isCollapsed) || disabled
    return (
      <TextArea
        disabled={componentDisabled}
        name={variable}
        onChange={e => _self.handleChange(e, variable, qs.name, QS_CONSTANTS.INPUT_TYPE.TEXT_AREA, qs, question, path)}
        value={value}
      />
    )
  }

  /**
   * Render a Inline Radio group question
   * @param qs
   * @param question
   * @param key
   * @param path
   * @param fieldDisabled
   */
  renderInlineRadioInput({ qs, question, value, path, disabled }) {
    const variable = question.variable
    const optionsID = this.getOption(question, path)
    const options = this.findOptionsInVocabulary(optionsID)
    return (
      <div>
        <table className='inlineRadioTable'>
          <tbody>
            <tr>{options.map((option, idx) => (<td key={idx}> {option.label}</td>))}</tr>
            <tr>
              {
                options.map((option, idx) => (
                  <td key={idx}>
                    <input
                      checked={option.value === value}
                      disabled={disabled}
                      name={variable + path.toString()}
                      onChange={() =>
                        this.handleRadioSelect(option.value, optionsID, variable, qs.name,
                          QS_CONSTANTS.INPUT_TYPE.RADIO, qs, question, path)}
                      type="radio"
                    />
                  </td>
                ))
              }
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  /**
   * Render a Radio group question
   * @param qs
   * @param question
   * @param key
   * @param path
   * @param fieldDisabled
   */
  renderRadioInput({ qs, question, value, path, disabled }) {
    const { RadioInput } = this.components

    const _self = this
    const variable = question.variable
    const optionsID = this.getOption(question, path)
    const options = this.findOptionsInVocabulary(optionsID).map(option => _self.getOptionDetails(option))

    const onChange = value => _self.handleRadioSelect(value, optionsID, variable, qs.name, QS_CONSTANTS.INPUT_TYPE.RADIO, qs, question, path)

    return (
      <RadioInput
        disabled={disabled}
        onChange={onChange}
        options={options}
        value={value}
        variable={variable}
      />
    )
  }
  /**
   * Render Checkbox input
   * @param {object} qs
   * @param {object} question
   * @param {String} key
   * @param {Array} path
   * @param fieldDisabled
   */
  renderCheckBoxInput({ qs, question, value, path, disabled }) {
    const _self = this
    const { CheckboxInput } = _self.components
    const variable = question.variable
    const optionsID = this.getOption(question, path)
    const options = this.findOptionsInVocabulary(optionsID).map(option => _self.getOptionDetails(option))
    const onChange = (value) => _self.handleCheckboxInput(
      value,
      optionsID,
      variable,
      qs.name,
      QS_CONSTANTS.INPUT_TYPE.CHECKBOX,
      qs,
      question,
      path
    )
    return (
      <CheckboxInput
        disabled={disabled}
        onChange={onChange}
        options={options}
        value={value}
        variable={variable}
      />
    )
  }

  /**
   * Render Checkbox input
   * @param {object} qs
   * @param {object} question
   * @param {String} key
   * @param {Array} path
   * @param fieldDisabled
   */
  renderCheckBoxImageInput({ qs, question, value, path, disabled }) {
    const variable = question.variable
    const optionsID = this.getOption(question, path)
    const options = this.findOptionsInVocabulary(optionsID)
    const width = Math.floor(100 / options.length) + '%'
    return (
      <table className="image-select">
        <tbody>
          <tr>
            {options.map((option, idx) => {
              return (<td key={idx} style={{ width: width }}>
                {option.image !== 'none' ? <img className="image-select-image" src={option.image} alt="" /> : null}
              </td>)
            })}
          </tr>
          <tr>
            {options.map((option, idx) => {
              return (<td key={idx} align="center" style={{ width: width }}>
                {option.value}
              </td>)
            })}
          </tr>
          <tr>
            {options.map((option, idx) => {
              return (<td key={idx} align="center" style={{ width: width }}>
                <input
                  type="checkbox"
                  checked={value ? value.indexOf(option.value) > -1 : false}
                  disabled={disabled}
                  onChange={() => {
                    this.handleCheckboxInput(option.value, optionsID, variable, qs.name,
                      QS_CONSTANTS.INPUT_TYPE.CHECKBOX_IMAGE, qs, question, path)
                  }}
                />
              </td>)
            })}
          </tr>
        </tbody>
      </table>
    )
  }

  /**
   * render a Date Picker
   * @param {Object} qs
   * @param {Object} question
   * @param {String} key
   * @param {Array} path
   * @param {Boolean} disabled
   * @return {XML}
   */
  renderDatePicker({ qs, question, value, path, disabled }) {
    const { DatePicker } = this.components
    const variable = question.variable

    return (
      <DatePicker
        onChange={date =>
          this.handleDatePickerChange(date, variable, qs.name, QS_CONSTANTS.INPUT_TYPE.DATE, qs, question, path)}
        disabled={disabled}
        inputProps={{ disabled }}
        placeholder='DD/MM/YYYY'
        value={value}
      />
    )
  }

  /**
   * Render a time picker
   * @param {Object} qs
   * @param {Object} question
   * @param {String} key
   * @param {Array} path
   * @param {Boolean} disabled
   * @return {XML}
   */
  renderTimePicker({ qs, question, value, path, disabled }) {
    const { TimePicker } = this.components
    const variable = question.variable
    const config = question.config
    return (
      <TimePicker
        value={value}
        onChange={time =>
          this.handleTimePickerChange(time, variable, qs.name, QS_CONSTANTS.INPUT_TYPE.TIME, qs, question, path)}
        disabled={disabled}
        question={question}
        {...config}
      />
    )
  }

  /**
   * Render a date time picker
   * @param {Object} qs
   * @param {Object} question
   * @param {String} key
   * @param {Array} path
   * @param {Boolean} disabled
   * @return {XML}
   */
  renderDateTimePicker({ qs, question, value, path, disabled }) {
    const { DateTimePicker } = this.components
    const variable = question.variable
    const config = question.config
    return (
      <DateTimePicker
        value={value}
        onChange={time =>
          this.handleTimePickerChange(time, variable, qs.name, QS_CONSTANTS.INPUT_TYPE.TIME, qs, question, path)}
        disabled={disabled}
        {...config}
      />
    )
  }

  /**
   * Render a text note
   * @param {String} key
   * @param {Array}  path Path to answer object
   * @param {object} q Question
   */
  renderTextNote(k, path, q) {
    const { PlainText } = this.components
    const { contentType, content } = q
    const ifQuestionDisplay = this.checkIfQuestionDisplay(path, q)
    if (!ifQuestionDisplay) {
      return null
    }
    let component
    switch (contentType) {
      case QS_CONSTANTS.CONTENT_TYPE.PLAIN_TEXT:
        component = <PlainText content={content} />
        break
      default:
        break
    }
    return (
      <div key={k} className="text-note">
        {component}
      </div>
    )
  }

  /**
   * Render a question.
   * @param {Object} qs Question set
   * @param {Object} question Question
   * @param {String} key Element key
   * @param {Array} path Path to answer object
   * @param {Boolean} fieldDisabled Check if question is disabled
   * @param {Number} sectionIdx Question section index
   * @returns {XML}
   */
  renderQuestion(qs, question, key, path, fieldDisabled, sectionIdx) {
    const _self = this
    const { Timer } = this.components

    const { isSaveButtonClicked, enableTimer } = this.props
    const { description, label, inputType, variable, hidden, readOnly } = question
    const value = this.getAnswerWithPath(path, variable)
    const errorMessage = _.get(_self.validations, [...path, variable])
    const sectionLabel = question.disableSectionLabel ? '' : `${sectionIdx + 1}. `

    const hasTimer = enableTimer && question.expiresIn
    const hasExpireTime = !!value.expireTime
    const ifQuestionDisplay = (this.checkIfQuestionDisplay(path, question) && !value.isHidden || _self.props.ignoreShowIf) && !hidden
    const disabled = fieldDisabled || (hasExpireTime ? value.expireTime < Date.now() : false) || readOnly
    const isErrorMsgShow = errorMessage || isSaveButtonClicked

    const onReady = expireTime => {
      if (!expireTime) {
        return
      }
      const answer = _self.getAnswerWithPath(path, variable)
      const newAnswer = {
        ...answer,
        ...{
          expireTime
        }
      }
      let newAnswers

      if (inputType === QS_CONSTANTS.INPUT_TYPE.SLIDER) {
        newAnswers = this.setSliderAnswer(variable, newAnswer, path)
      } else {
        newAnswers = this.setAnswer(variable, newAnswer, path)
      }
      this.initialAnswerObject(newAnswers)
      this.props.onAnswersChange(newAnswers)
    }

    const onComplete = () => {
      _self.forceUpdate()
    }
    // render question if current answers satisfy its showIf condition.
    if (ifQuestionDisplay) {
      const renderOptions = {
        qs,
        question,
        value,
        key,
        path,
        disabled
      }
      let Question = null
      switch (inputType) {
        case QS_CONSTANTS.INPUT_TYPE.TEXT:
          Question = this.renderTextInput(renderOptions)
          break
        case QS_CONSTANTS.INPUT_TYPE.TEXT_AREA:
          Question = this.renderTextArea(renderOptions)
          break
        case QS_CONSTANTS.INPUT_TYPE.DATE:
          Question = this.renderDatePicker(renderOptions)
          break
        case QS_CONSTANTS.INPUT_TYPE.TIME:
          Question = this.renderTimePicker(renderOptions)
          break
        case QS_CONSTANTS.INPUT_TYPE.DATE_TIME:
          Question = this.renderDateTimePicker(renderOptions)
          break
        case QS_CONSTANTS.INPUT_TYPE.SELECT:
          Question = this.renderSelectInput(renderOptions)
          break
        case QS_CONSTANTS.INPUT_TYPE.RADIO:
          Question = this.renderRadioInput(renderOptions)
          break
        case QS_CONSTANTS.INPUT_TYPE.CHECKBOX:
          Question = this.renderCheckBoxInput(renderOptions)
          break
        case 'slider':
          Question = this.renderSlider(renderOptions)
          break
        case QS_CONSTANTS.INPUT_TYPE.CHECKBOX_IMAGE:
          Question = this.renderCheckBoxImageInput(renderOptions)
          break
        case QS_CONSTANTS.INPUT_TYPE.INLINE_RADIO:
          Question = this.renderInlineRadioInput(renderOptions)
          break
        default:
          break
      }
      return (
        <div key={key} className="question form-group">
          <label>
            <span>{sectionLabel}{label}</span>
            {
              description
                ?
                <span>
                  <a className='help' data-tip={description} data-for={`question-description-${variable}`}>
                    <i className="fa fa-question-circle" />
                  </a>
                </span>
                :
                null
            }
            {
              this.isQuestionRequired(question)
                ? <span className="required" title="Required">*</span>
                : ''
            }
          </label>
          {
            description &&
            <ReactTooltip id={`question-description-${variable}`} class="tool-tip-window" aria-haspopup="true" place='right' effect='solid' />
          }
          <div className="marginTop">
            {Question}
          </div>
          <div hidden={!isErrorMsgShow} className="error">
            {errorMessage}
          </div>
          {
            hasTimer
              ?
              hasExpireTime
                ?
                <Timer
                  label={`Question ${key + 1}`}
                  expireTime={value.expireTime}
                  onReady={onReady}
                  onComplete={onComplete}
                />
                :
                <Timer
                  expireInSeconds={question.expiresIn}
                  label={`Question ${key + 1}`}
                  onReady={onReady}
                  onComplete={onComplete}
                />
              :
              null
          }
        </div>
      )
    }
  }


  /**
   * Render a question set.
   * @param {String} key Element key
   * @param {Array} path Path to answer.
   * @param {Object} qs Question set
   * @param {String} fieldDisabled Disable field flag
   * @param {Number} sectionIdx section index for section label
   * @returns {XML}
   */
  renderQuestionSet(key, path, qs, fieldDisabled, sectionIdx) {
    const hasSectionIdx = sectionIdx !== undefined
    const sectionLabels = path.length > 1 ? (qs.display === QS_CONSTANTS.REPEAT_SET ?
      this.getOrdinalPrefix(hasSectionIdx ? sectionIdx + 1 : key + 1) : this.getSubSectionLabel(hasSectionIdx ? sectionIdx : key))
      : this.getSectionLable(hasSectionIdx ? sectionIdx : key)

    const concatSymbol = qs.display !== QS_CONSTANTS.REPEAT_SET ? '. ' : ' '
    const questionSetLabel = qs.disableSectionLabel ? qs.label : `${sectionLabels}${concatSymbol}${qs.label}`
    let ifQuestionSetDisplay = this.checkIfQuestionDisplay(path, qs)
    const questionSetAnswer = this.getAnswerWithPath(path.slice(0, path.length - 1), path.slice(path.length - 1))
    const isQuestionSetDisplay = Object.keys(questionSetAnswer).reduce(
      (acc, cur, idx, arr) => {
        if (acc) {
          arr.splice(arr.length)
          return true
        } else {
          return !questionSetAnswer[cur].isHidden
        }
      },
      true
    )
    let videoId = qs.video ? qs.video.id : null
    if (ifQuestionSetDisplay && isQuestionSetDisplay) {
      return (
        <div key={key} name={qs.name} className="question-set">
          <div className="title">{questionSetLabel}</div>
          {/*Display video if video field is set*/}
          {
            videoId ? <RenderVideo videoId={videoId} /> : null
          }

          {qs.description && qs.description.length > 0 ?
            <div className="subtitle">
              {
                qs.description.map((p, index) => (
                  <p key={index}>{p}</p>
                ))
              }
            </div> : null
          }

          <div className="section" id={qs.name} key={key}>
            {
              qs.questions.length > 0 &&
              qs.questions.reduce((acc, q, index) => {
                if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION) {
                  acc.components.push(this.renderQuestion(qs, q, index, path, fieldDisabled, acc.currentIndex))
                } else if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION_SET && q.display &&
                  q.display === QS_CONSTANTS.REPEAT_SET) {
                  const reference = q.reference
                  const paths = this.getDominantPath(path)
                  let num = 0
                  paths.forEach((p) => {
                    const answer = this.getAnswerWithPath(p, reference)
                    if (!isNaN(parseInt(answer))) {
                      num = parseInt(answer)
                    }
                  })
                  // let num = this.getAnswerWithPath(path, reference);
                  const newPath = [...path]
                  newPath.push(q.name)
                  acc.components.push(_.range(0, num).map((k) => {
                    const pathWithIndex = [...newPath]
                    pathWithIndex.push(k)
                    if (q.format && q.format === QS_CONSTANTS.QUESTION_FORMAT.ROW) {
                      return this.renderQuestionSetToRow(k, pathWithIndex, q, fieldDisabled)
                    }
                    return this.renderQuestionSet(k, pathWithIndex, q, fieldDisabled)
                  }))
                } else if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION_SET && q.alignment === 'TABLE') {
                  const paths = _.cloneDeep(path)
                  paths.push(q.name)
                  acc.components.push(this.renderTableQuestions(index, paths, q, fieldDisabled, acc.currentIndex))
                } else if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION_SET) {
                  const paths = _.cloneDeep(path)
                  paths.push(q.name)
                  acc.components.push(this.renderQuestionSet(index, paths, q, fieldDisabled, acc.currentIndex))
                } else if (q.type === QS_CONSTANTS.QUESTION_TYPE.TEXT_NOTE) {
                  acc.components.push(this.renderTextNote(index, path, q))
                }
                if (q.type !== QS_CONSTANTS.QUESTION_TYPE.TEXT_NOTE && !q.disableSectionLabel) {
                  acc.currentIndex++
                }
                if (index === qs.questions.length - 1) {
                  return acc.components
                }
                return acc
              }, { components: [], currentIndex: 0 })
            }
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  /**
   * Render a question set in a table.
   * @param {String} key Element key
   * @param {Array} path Path to answer.
   * @param {Object} qs Question set
   * @param {String} fieldDisabled Disable field flag
   * @returns {XML}
   */
  renderTableQuestions(key, path, qs, fieldDisabled, sectionIndex) {
    const _self = this
    const { Table } = _self.components
    let ifQuestionSetDisplay = this.checkIfQuestionDisplay(path, qs)
    if (ifQuestionSetDisplay) {
      const sectionLabels = this.getSubSectionLabel(sectionIndex)
      const questionSetLabel = `${sectionLabels}. ${qs.label}`
      const questionSetAnswers = _self.getQuestionSetAnswers(path)
      const validations = _.get(_self.validations, path)
      const collapseQuestion = ({ question, value }) => {
        const questionPath = [...path, question.variable]
        let answers = _.cloneDeep(_self.props.answers)
        const newVal = _.cloneDeep(value)
        const variablePaths = _self.getFormulaVariables(question.formula)
        const values = {}
        variablePaths.forEach(path => {
          const answer = _.get(answers, path)
          const value = _.isObject(answer) ? Number(answer.value) : 0
          values[path] = _.isNaN(value) ? 0 : value
          answer.isHidden = !newVal.isCollapsed
          answers = _.set(answers, path, answer)
        })
        newVal.isCollapsed = !newVal.isCollapsed
        if (!newVal.isCollapsed) {
          newVal.value = _self.getFormulaResult(question.formula, values).toString()
        }

        answers = _.set(answers, questionPath, newVal)
        _self.initialAnswerObject(answers)
        _self.props.onAnswersChange(answers)
      }
      return (
        <div key={key} name={qs.name} className="question form-group">
          <label>{questionSetLabel}</label>
          <Table
            onChange={_self.handleChange}
            path={path}
            questionSetAnswers={questionSetAnswers}
            questionSet={qs}
            disabled={fieldDisabled}
            validations={validations}
            collapseQuestion={collapseQuestion}
            checkIfQuestionDisplay={_self.checkIfQuestionDisplay}
          />
        </div>
      )
    } else {
      return null
    }
  }



  /**
   * Render a set of question which looks like a table. Each row contains a Question Set.
   * @param {String} key key of the question set.
   * @param {Array} path path to its answer object.
   * @param {Object} qs question set to be rendered.
   * @returns {XML} HTML
   */
  renderQuestionSetToRow(key, path, qs, disabled) {
    const _self = this
    return (
      <div key={key} className="row-input-wrapper">
        {/* {key === 0 && <section><h3>{qs.name}:</h3></section>} */}
        <div className="row-input">
          <div className="row-input-row">
            {key === 0 && qs.questions.map((q, k1) => (
              <div className="row-input-element" key={k1}>{q.label}</div>
            ))}
          </div>
          <div key={key} className="row-input-row">
            {qs.questions.map((q, k) => {
              if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION) {
                const value = _self.getAnswerWithPath(path, q.variable)
                const renderOptions = {
                  qs,
                  question: q,
                  value,
                  key: k,
                  path,
                  disabled
                }
                return (<div key={k} className="row-input-element">
                  {q.inputType === QS_CONSTANTS.INPUT_TYPE.TEXT ?
                    this.renderTextInput(renderOptions) : null}
                  {q.inputType === QS_CONSTANTS.INPUT_TYPE.SELECT ?
                    this.renderSelectInput(renderOptions) : null}
                </div>)
              }
              return null
            })
            }
          </div>
        </div>
      </div>

    )
  }
  /**
   *  Update answers record in redux store
   */
  sendAnswerDataToReduxStore(answers) {
    this.props.onAnswersChange(answers)
  }
  /**
   * Reset input answer to empty string
   * @param {*} name answer variable
   * @param {*} path answer path
   */
  resetAnswer(name, path) {
    const _self = this

    if (_.isEmpty(_self.props.answers)) {
      return [_self.props.answers, false]
    }
    let answers = _.cloneDeep(_self.props.answers)
    const answer = _.get(answers, path)
    const isChanged = answer && answer[name] && (Array.isArray(answer[name]) ? answer[name].value !== '' : answer[name].value.length !== 0)
    if (isChanged) {
      answers = _.set(answers, [...path, name], { ...answer[name], value: '' })
      _self.props.onAnswersChange(answers)
    }
  }

  /**
   *  Change answer with the path to the answer.
   * @param {String} name variableName
   * @param {String} value the value of answer to be updated.
   * @param {Array} path the path to the position in question set.
   * @param {boolean} mutable
   * @return {Object} Answer Object
   */
  setAnswer(name, value, path, answers, mutable) {
    const state = mutable && answers ? answers : _.cloneDeep(answers || this.props.answers)
    const answer = path.reduce((o, i) => o[i], state)
    answer[name] = value
    return state
  }

  /**
   * Function to change Change answer with the path to the answer when the slider value changes.
   * @param {String} name variableName
   * @param {String} value the value of answer to be updated.
   * @param {Array} path the path to the position in question set.
   * @return {Object} Answer Object
   */
  setSliderAnswer(name, value, path, answers) {
    const state = _.cloneDeep(answers || this.props.answers)
    const answer = path.reduce((o, i) => o[i], state)
    answer[name] = value
    return state
  }

  /**
   * udpate Progress record in local variable
   * @param {Array} path
   * @param {Object} q
   * @param {Object} questionSetInAnswer
   */
  updateProgress(path, q, questionSetInValidation, questionSetInAnswer) {
    const variable = q.variable
    const valueRecord = {
      isValid: !questionSetInValidation[variable],
      value: questionSetInAnswer[variable],
      isRequired: q.validation ? q.validation.isRequired : false,
      ignore: !!q.formula || questionSetInAnswer[variable].isHidden
    }
    const pathToValue = `${path},${variable}`
    this.progress[pathToValue] = valueRecord
    // if (q.showIf) {
    //   const ifQuestionDisplay = this.checkIfQuestionDisplay(path, q)
    //   if (!ifQuestionDisplay) {
    //     delete this.progress[pathToValue]
    //     this.resetAnswer(variable, path)
    //   }
    // }
  }
}

StudyQuestionsForm.propTypes = {
  answerProgress: PropType.number,
  answers: PropType.object,
  components: PropType.object,
  currentSectionIndex: PropType.number,
  enableTimer: PropType.bool,
  fieldDisabled: PropType.bool,
  handleSubmit: PropType.func,
  isSaveButtonClicked: PropType.bool,
  name: PropType.string,
  onAnswersChange: PropType.func,
  onProgressChange: PropType.func,
  progress: PropType.object,
  questionSet: PropType.object,
  studyProfile: PropType.object,
  vocabularies: PropType.array,
  ignoreShowIf: PropType.bool
}

const areEqual = (prevProps, newProps) => _.isEqual(prevProps, newProps)

export default React.memo(StudyQuestionsForm, areEqual)
