import Datetime from 'react-datetime'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'

import styles from './index.module.scss'
import { QS_CONSTANTS } from '../../constants'

const DateTimePicker = props => {
  const { disabled, timeFormat, dateFormat, placeholder } = props
  const format = `${dateFormat} ${timeFormat}`

  let value
  if (props.value.value) {
    try {
      value = moment(props.value.value, format)
    } catch(err) {
      value = new Date(props.value.value)
    }
  } else {
    value = ''
  }

  const onChange = dateTime => {
    if (dateTime instanceof moment && dateTime.isValid()) {
      const value = dateTime.format(format)
      props.onChange({
        value
      })
    }
  }

  return (
    <div>
      <Datetime
        className={styles['dateTime__container']}
        value={value}
        onChange={onChange}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        placeholder={placeholder}
        inputProps={{ disabled, placeholder }}
      />
    </div>
  )
}

DateTimePicker.defaultProps = {
  timeFormat: QS_CONSTANTS.DEFAULT_TIME_FORMAT,
  dateFormat: QS_CONSTANTS.DEFAULT_DATE_FORMAT,
  placeholder: `${QS_CONSTANTS.DEFAULT_DATE_FORMAT} ${QS_CONSTANTS.DEFAULT_TIME_FORMAT}`
}

DateTimePicker.propTypes = {
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  timeFormat: PropTypes.string,
  value: PropTypes.object.isRequired,
}

export default DateTimePicker