// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../example/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Timer_timer__wrapper__3QOIi {\n  align-items: center;\n  background-color: #ffffff;\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 10px);\n  left: 5px;\n  opacity: 0.95;\n  padding-top: 20px;\n  position: absolute;\n  text-align: center;\n  top: 5px;\n  transition: all 0.3s;\n  width: calc(100% - 10px);\n  z-index: 1000; }\n\n.Timer_timer__wrapper--hidden__cjrfT {\n  opacity: 0;\n  z-index: -1; }\n\n.Timer_wrapper__start__2ycDv {\n  align-items: center;\n  background: transparent !important;\n  border-color: #ffffff;\n  border-radius: 4px;\n  border-style: solid;\n  border-width: 1px;\n  cursor: pointer;\n  display: flex;\n  height: 24px;\n  justify-content: space-around;\n  line-height: 1.5;\n  padding: 0 7px;\n  transition: all 0.3s; }\n  .Timer_wrapper__start__2ycDv:hover {\n    border-color: #000000a6;\n    opacity: 0.8; }\n  .Timer_wrapper__start__2ycDv i + span {\n    margin-left: 8px; }\n\n.Timer_timer__countDown__3chkN {\n  position: relative;\n  font-weight: 500;\n  margin-bottom: 5px; }\n", ""]);
// Exports
exports.locals = {
	"timer__wrapper": "Timer_timer__wrapper__3QOIi",
	"timer__wrapper--hidden": "Timer_timer__wrapper--hidden__cjrfT",
	"wrapper__start": "Timer_wrapper__start__2ycDv",
	"timer__countDown": "Timer_timer__countDown__3chkN"
};
module.exports = exports;
