// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../example/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextArea_textArea__1oL0M {\n  background-color: #fff;\n  border-collapse: separate;\n  border-color: #d9d9d9 #ccc #b3b3b3;\n  border-radius: 4px;\n  border-spacing: 0;\n  border: 1px solid #ccc;\n  box-sizing: border-box;\n  color: #333;\n  outline: none;\n  padding-left: 10px;\n  position: relative;\n  width: 100%;\n  min-height: 36px; }\n  .TextArea_textArea__1oL0M:focus {\n    border: 1px solid #6fc7fe; }\n\n.TextArea_disabled__2eVnQ {\n  background-color: #f5f5f5;\n  opacity: 1;\n  cursor: not-allowed;\n  color: black; }\n", ""]);
// Exports
exports.locals = {
	"textArea": "TextArea_textArea__1oL0M",
	"disabled": "TextArea_disabled__2eVnQ"
};
module.exports = exports;
