import CheckboxInput from './CheckboxInput'
import DatePicker from './DatePicker'
import DateTimePicker from './DateTimePicker'
import PlainText from './PlainText'
import RadioInput from './RadioInput'
import SelectInput from './SelectInput'
import Table from './Table'
import TextArea from './TextArea'
import TextInput from './TextInput'
import TimePicker from './TimePicker'
import Timer from './Timer'

export default {
  CheckboxInput,
  DatePicker,
  DateTimePicker,
  PlainText,
  RadioInput,
  SelectInput,
  Table,
  TextArea,
  TextInput,
  TimePicker,
  Timer
}