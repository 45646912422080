import React, { useState } from 'react'
import PropTypes from 'prop-types'

import style from './index.module.scss'
import classNames from 'classnames'

const TextArea = props => {
  const {
    disabled,
    name
  } = props

  const [height, setHeight] = useState(null)
  const classNameOptions = {}
  classNameOptions[style['textArea']] = true
  classNameOptions[style['disabled']] = disabled
  const className = classNames(classNameOptions)

  const value = props.value.value
  const onRef = (target) => {
    if (target && target.scrollHeight) {
      const height = target.scrollHeight + target.style.paddingTop || 0 + target.style.paddingBottom || 0
      setHeight(`${height}px`)
    }
  }
  const onChange = e => {
    const newValue = {
      value: e.target.value
    }

    props.onChange({
      target: {
        name,
        value: { ...props.value, ...newValue }
      }
    })
  }

  return (
    <div>
      <textarea
        className={className}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ref={onRef}
        style={{ height }}
      />
    </div>
  )
}

TextArea.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
}

export default React.memo(TextArea)