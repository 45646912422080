import React from 'react'
import PropTypes from 'prop-types'
import { chunk } from 'lodash'
import classNames from 'classnames'
import styles from './index.module.scss'

const CheckBoxInput = (props) => {
  const { disabled, options, variable } = props
  const value = props.value.value || []
  const textValue = props.value.textValue || {}
  const onOptionChange = (optionValue) => {
    const newOptionVal = [...value]
    const newTextValue = Object.assign({}, textValue)
    let isUncheck = false
    const index = newOptionVal.findIndex((el) => el === optionValue)
    if (index !== -1) {
      newOptionVal.splice(index, 1)
      isUncheck = true
    } else {
      newOptionVal.push(optionValue)
    }
    if (isUncheck) {
      delete newTextValue[optionValue]
    }
    const newVal = {
      ...props.value,
      value: newOptionVal,
      textValue: newTextValue
    }
    props.onChange(newVal)
  }

  const onTextChange = (e, optionValue) => {
    const newOptionVal = [...value]
    const newTextValue = Object.assign({}, textValue)
    newTextValue[optionValue] = e.target.value
    const newVal = {
      ...props.value,
      value: newOptionVal,
      textValue: newTextValue
    }
    props.onChange(newVal)
  }

  if (options && options.length < 5) {
    return (
      <div className={styles['checkbox__container']}>
        {options.map((option, idx) => {
          const { optionLabel, optionValue, optionEnableTextInput } = option
          const isChecked = value.indexOf(optionValue) > -1
          return (
            <div className={styles['option__container']} key={idx}>
              <input
                checked={isChecked}
                className={styles['option__select']}
                disabled={disabled}
                name={variable}
                onChange={() => onOptionChange(optionValue)}
                type='checkbox'
              />
              <label className={styles['option__label']}>{optionLabel}</label>
              {
                optionEnableTextInput && isChecked && (
                  <input
                    className={classNames({
                      'text-input': true,
                      [styles['text__input--inline']]: true
                    })}
                    disabled={disabled}
                    onChange={(e) => onTextChange(e, optionValue)}
                    placeholder='Please specify other'
                    type='text'
                    value={textValue[optionValue]}
                  />
                )
              }
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div className={styles['checkbox__container']}>
      {chunk(options, 2).map((setOfOption, idx1) => (
        <div className={styles['checkbox__row']} key={idx1}>
          {setOfOption.map((option, idx2) => {
            const { optionLabel, optionValue, optionEnableTextInput } = option
            const isChecked = value.indexOf(optionValue) > -1
            return (
              <div className={styles['option__container--col-12']} key={idx2}>
                <input
                  checked={isChecked}
                  className={styles['option__select']}
                  disabled={disabled}
                  name={variable}
                  onChange={() => onOptionChange(optionValue)}
                  type='checkbox'
                />
                <label className={styles['option__label']}>{optionLabel}</label>
                {
                  optionEnableTextInput && isChecked && (
                    <input
                      className={classNames({
                        'text-input': true,
                        [styles['text__input--inline']]: true
                      })}
                      disabled={disabled}
                      onChange={(e) => onTextChange(e, optionValue)}
                      placeholder='Please specify other'
                      type='text'
                      value={textValue[optionValue]}
                    />
                  )
                }
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

CheckBoxInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  variable: PropTypes.string.isRequired
}

export default CheckBoxInput
